import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaBaby, FaAmazon } from 'react-icons/fa';
import { BsTagFill } from 'react-icons/bs';

const HomePage = ({ brands, sizes, types, brand, size, type, handleBrandChange, handleSizeChange, handleTypeChange, loading, diapers, getPriceInfo, getAmazonLink }) => {
  const calculateDiscountAmount = (price, discountRate) => {
    return Math.round(price * (discountRate / 100));
  };

  const calculateLowestPrice = (priceInfo, diaper) => {
    let lowestPrice = priceInfo.price;
    let isSubscribePrice = false;
    let isCouponApplied = false;

    // 定期便の価格がある場合はそちらを使用
    if (diaper.Subscribe_Availability === 'あり' && diaper.Subscribe_And_Save_Price) {
      lowestPrice = diaper.Subscribe_And_Save_Price;
      isSubscribePrice = true;
    }

    // クーポンの割引を適用（定期便価格にも適用可能と仮定）
    if (diaper.Coupon_Availability === 'あり' && diaper.Coupon_Discount_Rate) {
      const couponPrice = lowestPrice - calculateDiscountAmount(lowestPrice, diaper.Coupon_Discount_Rate);
      if (couponPrice < lowestPrice) {
        lowestPrice = couponPrice;
        isCouponApplied = true;
      }
    }

    const pricePerUnit = lowestPrice / diaper.Count;
    return { lowestPrice, pricePerUnit: pricePerUnit.toFixed(2), isSubscribePrice, isCouponApplied };
  };

  return (
    <>
      <section className="mb-6 bg-custom-green-100 p-4 rounded-lg">
        <h2 className="text-xl md:text-2xl font-semibold mb-3 text-center text-custom-green-800">ブランドとサイズを選択</h2>
        <div className="flex flex-wrap justify-center gap-2">
          <select
            className="px-3 py-2 rounded-md bg-white border border-custom-green-300 text-custom-green-700 focus:outline-none focus:ring-2 focus:ring-custom-green-400"
            onChange={(e) => handleBrandChange(e.target.value)}
            value={brand}
          >
            <option value="">全てのブランド</option>
            {brands.map((brandName) => (
              <option key={brandName} value={brandName}>{brandName}</option>
            ))}
          </select>
          <select
            className="px-3 py-2 rounded-md bg-white border border-custom-green-300 text-custom-green-700 focus:outline-none focus:ring-2 focus:ring-custom-green-400"
            onChange={(e) => handleSizeChange(e.target.value)}
            value={size}
          >
            {sizes.map((sizeName) => (
              <option key={sizeName} value={sizeName}>{sizeName}</option>
            ))}
          </select>
          <select
            className="px-3 py-2 rounded-md bg-white border border-custom-green-300 text-custom-green-700 focus:outline-none focus:ring-2 focus:ring-custom-green-400"
            onChange={(e) => handleTypeChange(e.target.value)}
            value={type}
            disabled={size === '新生児'}
          >
            <option value="">全てのタイプ</option>
            {types.map((typeName) => (
              <option key={typeName} value={typeName}>{typeName}</option>
            ))}
          </select>
        </div>
      </section>

      <section className="mb-6 bg-custom-green-50 p-4 rounded-lg shadow-md">
        <h3 className="text-lg font-semibold text-custom-green-800 mb-2">お得なおむつ探しをサポート！</h3>
        <p className="text-sm text-gray-700 leading-relaxed">
          毎日更新の価格ランキングで、赤ちゃんのためのベストな選択を。パパ・ママの味方、おむつ価格ランキングサイトです。
        </p>
        <p className="text-xs text-gray-500 mt-2 italic">
          ※ 表示価格は取得時点のものです。最新の価格は販売サイトでご確認ください。
        </p>
      </section>

      {loading ? (
        <div className="text-center text-custom-green-600 font-bold">
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            className="inline-block"
          >
            <FaBaby size={24} />
          </motion.div>
          <p className="mt-2">データを取得中...</p>
        </div>
      ) : (
        <AnimatePresence>
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6"
          >
            {diapers.length > 0 ? (
              diapers.map((diaper, index) => {
                const priceInfo = getPriceInfo(diaper);
                const { lowestPrice, pricePerUnit, isSubscribePrice, isCouponApplied } = calculateLowestPrice(priceInfo, diaper);
                const timestamp = new Date(diaper.Timestamp);
                const formattedDate = `${timestamp.getFullYear()}年${String(timestamp.getMonth() + 1).padStart(2, '0')}月${String(timestamp.getDate()).padStart(2, '0')}日${String(timestamp.getHours()).padStart(2, '0')}時頃`;
                const hasSubscribe = diaper.Subscribe_Availability === 'あり' && diaper.Subscribe_Discount_Rate;
                const hasCoupon = diaper.Coupon_Availability === 'あり' && diaper.Coupon_Discount_Rate;
                const hasSpecialOffer = diaper.Special_Offer && diaper.Special_Offer !== 'なし';

                return (
                  <motion.div
                    key={`${diaper.ASIN}-${index}`}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    className="border p-4 rounded-lg shadow-lg bg-white hover:shadow-xl transition-all duration-300"
                  >
                    <h2 className="text-lg font-bold mb-2 truncate text-gray-800">{index + 1}位: {diaper.Title}</h2>
                    <div className="flex flex-col sm:flex-row items-center">
                      <img src={diaper.Image_URL} alt={diaper.Title} className="w-24 h-24 object-cover rounded-md mb-2 sm:mb-0 sm:mr-4" />
                      <div className="flex-1">
                        <p className="text-gray-600 text-sm mb-1">ブランド: {diaper.Brand}</p>
                        <p className="text-gray-600 text-sm mb-1">サイズ: {diaper.Size}</p>
                        <p className="text-gray-600 text-sm mb-1">枚数: {diaper.Count}</p>
                        <p className="text-gray-600 text-sm mb-1">価格: ¥{lowestPrice.toLocaleString()}</p>
                        <p className="text-gray-800 font-bold text-lg">
                          1枚あたり: ¥{pricePerUnit}<br/>
                          <span className="text-sm ml-2">
                            {isSubscribePrice && "(定期便価格)"}
                            {isCouponApplied && "(クーポン適用済み)"}
                          </span>
                        </p>
                        <div className="flex flex-wrap gap-2 mt-1">
                          {hasSubscribe && (
                            <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                              定期便 {diaper.Subscribe_Discount_Rate}%オフ
                            </span>
                          )}
                          {hasCoupon && (
                            <span className="bg-yellow-100 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded">
                              クーポン {diaper.Coupon_Discount_Rate}%オフ
                            </span>
                          )}
                        </div>
                        {hasSpecialOffer && (
                          <p className="text-red-600 font-bold text-sm mt-1 flex items-center">
                            <BsTagFill className="mr-1" /> お得な情報: {diaper.Special_Offer}
                          </p>
                        )}
                        <p className="text-gray-500 text-xs mt-1">取得日時: {formattedDate}</p>
                        <a
                          href={getAmazonLink(diaper.ASIN)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mt-2 inline-flex items-center bg-custom-green-500 text-white px-3 py-1 rounded-md hover:bg-custom-green-600 transition-colors duration-200 text-sm"
                        >
                          <FaAmazon className="mr-1" /> Amazonで購入
                        </a>
                      </div>
                    </div>
                  </motion.div>
                );
              })
            ) : (
              <div className="col-span-full text-center text-custom-green-600 font-bold text-xl">
                データがありません
              </div>
            )}
          </motion.section>
        </AnimatePresence>
      )}

      <section className="mt-8 bg-custom-green-50 p-6 rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-4 text-custom-green-800">サイトについて</h2>
        <div className="space-y-4">
          <p className="text-custom-green-700 font-semibold text-lg">
            おむつ価格ランキングサイトは、赤ちゃんのためのおむつを最安値で購入したいパパ・ママを応援します！
          </p>
          <ul className="list-disc list-inside space-y-2 text-custom-green-700">
            <li>毎日更新の価格ランキングで、通販の安いおむつを簡単に発見</li>
            <li>1枚あたりの単価表示で、真のお得さを一目で把握</li>
            <li>ブランドやサイズの絞り込みで、欲しいおむつをすぐに検索</li>
            <li>Amazonの商品ページへのリンクで、即座に購入可能</li>
          </ul>
          <p className="text-custom-green-700 font-semibold text-lg italic">
            毎日のおむつ選びをもっと簡単に、もっとお得に。<br/>
            おむつ価格ランキングサイトで、賢いおむつ選びを始めましょう！
          </p>
        </div>
      </section>
    </>
  );
};

export default HomePage;