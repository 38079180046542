import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBaby, FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-custom-green-100 shadow-md">
      <div className="container mx-auto px-4 py-3">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center text-custom-green-800 text-2xl font-bold">
            <FaBaby className="mr-2" />
            おむつ価格ランキング
          </Link>
          <nav className="hidden md:block">
            <ul className="flex space-x-4">
              <li><Link to="/" className="text-custom-green-700 hover:text-custom-green-900 transition-colors duration-200">ホーム</Link></li>
              <li><Link to="/about" className="text-custom-green-700 hover:text-custom-green-900 transition-colors duration-200">運営者情報</Link></li>
              <li><Link to="/privacy-policy" className="text-custom-green-700 hover:text-custom-green-900 transition-colors duration-200">プライバシーポリシー</Link></li>
              <li><Link to="/sitemap" className="text-custom-green-700 hover:text-custom-green-900 transition-colors duration-200">サイトマップ</Link></li>
            </ul>
          </nav>
          <button onClick={toggleMenu} className="md:hidden text-custom-green-800">
            {isMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <nav className="md:hidden bg-custom-green-50 p-4">
          <ul className="space-y-2">
            <li><Link to="/" className="block text-custom-green-700 hover:text-custom-green-900 transition-colors duration-200" onClick={toggleMenu}>ホーム</Link></li>
            <li><Link to="/about" className="block text-custom-green-700 hover:text-custom-green-900 transition-colors duration-200" onClick={toggleMenu}>運営者情報</Link></li>
            <li><Link to="/privacy-policy" className="block text-custom-green-700 hover:text-custom-green-900 transition-colors duration-200" onClick={toggleMenu}>プライバシーポリシー</Link></li>
            <li><Link to="/sitemap" className="block text-custom-green-700 hover:text-custom-green-900 transition-colors duration-200" onClick={toggleMenu}>サイトマップ</Link></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Header;
