import React from 'react';
import { Link } from 'react-router-dom';

const Sitemap = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4 text-pink-600">サイトマップ</h1>
      <ul className="space-y-2">
        <li>
          <Link to="/" className="text-pink-500 hover:underline">ホーム</Link>
          <p className="text-sm text-gray-600 ml-4">おむつ価格ランキングのメインページ</p>
        </li>
        <li>
          <Link to="/privacy-policy" className="text-pink-500 hover:underline">プライバシーポリシー</Link>
          <p className="text-sm text-gray-600 ml-4">個人情報の取り扱いについて</p>
        </li>
        <li>
          <Link to="/about" className="text-pink-500 hover:underline">運営者情報</Link>
          <p className="text-sm text-gray-600 ml-4">サイトの目的と運営者について</p>
        </li>
        <li>
          <Link to="/sitemap" className="text-pink-500 hover:underline">サイトマップ</Link>
          <p className="text-sm text-gray-600 ml-4">サイト内のページ一覧</p>
        </li>
      </ul>
    </div>
  );
};

export default Sitemap;
