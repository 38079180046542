import React from 'react';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4 text-pink-600">運営者情報</h1>
      <ul className="space-y-2">
        <li><strong>運営者名：</strong>おむつ価格ランキング</li>
        <li><strong>運営者メールアドレス：</strong>info@deal-hunter.net</li>
        <li><strong>公式Xアカウント：</strong><a href="https://x.com/dealhunter72717" target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:underline">@dealhunter72717</a></li>
        <li><strong>サイトの目的：</strong>赤ちゃんのおむつを最安値で購入したいパパ・ママを支援すること</li>
        <li><strong>更新頻度：</strong>おむつの価格情報は毎日更新されます</li>
        <li><strong>免責事項：</strong>当サイトの情報は可能な限り正確を期していますが、価格や在庫状況は常に変動する可能性があります。最新の情報は各販売サイトでご確認ください。</li>
      </ul>
      <p className="mt-4">
        ご質問やお問い合わせは、上記メールアドレスまでお気軽にご連絡ください。
      </p>
      <div className="mt-6">
        <Link to="/" className="text-pink-600 hover:underline">トップページに戻る</Link>
      </div>
    </div>
  );
};

export default About;