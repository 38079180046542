import { useEffect } from 'react'
import OneSignal from 'react-onesignal'

export const OneSignalInitial = () => {
  useEffect(() => {
    const oneSignalInit = async () => {
      await OneSignal.init({
        appId: process.env.REACT_APP_ONESIGNAL_APP_ID || '',
        allowLocalhostAsSecureOrigin: true,
        notifyButton: {
          enable: true,
          size: 'medium',
          theme: 'default',
          position: 'bottom-right',
          offset: {
            bottom: '20px',
            right: '20px',
          },
          showCredit: false,
          text: {
            'tip.state.unsubscribed': '通知を受け取る',
            'tip.state.subscribed': '通知が有効です',
            'tip.state.blocked': '通知がブロックされています',
            'message.prenotify': '通知を受け取るにはクリックしてください',
            'message.action.subscribed': '通知が有効になりました！',
            'message.action.resubscribed': '通知が再度有効になりました！',
            'message.action.unsubscribed': '今後通知は送信されません',
            'dialog.main.title': '通知の管理',
            'dialog.main.button.subscribe': '通知を有効にする',
            'dialog.main.button.unsubscribe': '通知を無効にする',
            'dialog.blocked.title': '通知のブロックを解除',
            'dialog.blocked.message': 'ブラウザの設定で通知を許可してください'
          }
        },
        promptOptions: {
          slidedown: {
            prompts: [
              {
                type: "push",
                autoPrompt: true,
                text: {
                  actionMessage: "おむつの価格情報をプッシュ通知で受け取りませんか？",
                  acceptButton: "はい",
                  cancelButton: "後で"
                },
                delay: {
                  pageViews: 1,
                  timeDelay: 10
                }
              }
            ]
          }
        }
      });
    }
    oneSignalInit()
  }, [])
  return null
}